import BlockContent from "@sanity/block-content-to-react";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";

const HeroPage: React.FC<any> = (props: { hero: any }) => {
  const { hero } = props;
  return (
    <div className={"o-hero-page"}>
      <div className="container flex-center--column">
        <div className="hero-page__title">
          {/*<h1 className={"a-title--hero-page"}>{hero.title.es_AR[0]._rawChildren[0].text}</h1>*/}
          <BlockContent blocks={hero.title._rawEsAr} />
        </div>
        <div className="hero-page__desc">
          <BlockContent blocks={hero.desc._rawEsAr} />
        </div>
        <div className="hero-page">
          <div className={"hero-page__img"}>
            {/*<SanityImage*/}
            {/*    {...hero.img}*/}
            {/*    width={680}*/}
            {/*    alt={hero.title.es_AR[0].children[0].text}*/}
            {/*/>*/}
            <GatsbyImage
              image={hero.img.asset.gatsbyImageData}
              alt={hero.title.es_AR[0].children[0].text}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroPage;
